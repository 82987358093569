<template>
  <div class="plesk-context-menu">
    <div v-for="(group, i) in menu" :key="i" class="plesk-context-menu__group">
      <component
        :is="item.attrs ? 'a' : 'div'"
        v-for="item in group"
        :key="item.key"
        v-bind="item.attrs"
        class="plesk-context-menu__item standart-title"
        :class="{ [`plesk-context-menu__item--${item.color}`]: !!item.color }"
        :disabled="item.disabled"
        @click="onClick(item)"
      >
        {{ $t(`actions.${item.key}`) }}
      </component>
    </div>
  </div>
</template>

<script>
import { BillMgrTool } from '@/models/BillMgr/Tools';
import serialize from '@/utils/serialize';
import { PleskTariff } from '@/models/BillMgr/PleskTariff';
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import Instruction from '@/layouts/Plesk/components/Instruction';
export default {
  name: 'PleskContextMenu',
  mixins: [showErrorModal],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof PleskTariff,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOther: [
        // {
        //   key: 'createBackup',
        //   disabled: true,
        // },
        {
          key: this.tariff.status.code === 1 ? 'removeOrder' : 'removeHosting',
          color: 'del',
          disabled: this.tariff.status.code !== 1,
          handler: this.tariff.status.code === 1 ? this.deleteFromBasket : null,
        },
      ],
    };
  },
  computed: {
    tools() {
      return this.$store.state.modulePlesk.tools;
    },
    menu() {
      const { gotoserver } = this.tools;
      const arr = [[]];
      if (gotoserver) {
        arr[0].push(
          {
            key: 'serverPanel',
            tool: gotoserver,
            disabled: !gotoserver.isEnable(this.tariff),
          },
          {
            key: 'instruction',
            handler: 'func',
          }
        );
      }
      arr[0].push(...this.menuOther);
      return arr;
    },
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.key === 'instruction') return this.openInstruction();
      if (item.handler && item.handler === 'func') this.askSure(item.key);
      else if (item.handler && typeof item.handler === 'function') item.handler(item);
      else if (item.tool && item.tool instanceof BillMgrTool) {
        try {
          this.handleBillMgrTool(item);
        } catch (e) {
          console.error(e);
        }
      } else if (!item.attrs) alert(`id: ${this.tariff.id}, \r\naction: ${item.title}`);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('modulePlesk/fetchBillMgrToolAction', {
          func: item.tool.func,
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        })
        .catch(e => {
          this.showError(e);
        });
    },
    deleteFromBasket() {
      let modal = null;
      this.$modals.open({
        name: 'RemoveBasketResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleBasket/removeFromBasket', this.tariff.id)
        .then(() => {
          Vue.set(modal, 'component', null);
          Vue.set(modal, 'text', this.$t('modal.removeFromBasket.success'));
          Vue.set(modal, 'closable', true);
          this.$store.dispatch('modulePlesk/updateList');
          if (this.$route.params.id && this.$route.params.id == this.tariff.id) {
            this.$router.push({ name: 'pleskMain' });
          }
        })
        .catch(e => this.showError(e));
    },
    openInstruction(props = {}) {
      let self = null;
      this.$modals.open({
        name: 'Instruction',
        size: 'big',
        component: Instruction,
        closeOnBackdrop: false,
        props: {
          tariff: this.tariff,
        },
        // title: this.$t('new_ticket'),
        on: {},
        onOpen: instance => (self = instance),
        onClose: () => {
          self = null;
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "actions": {
      "change": "изменить",
      "stop": "остановить",
      "panel": "перейти в панель",
      "history": "история",
      "serverPanel":"перейти в панель Plesk",

      "instruction": "Инструкция",

      "start": "включить",
      "process": "в процессе",
      "restart": "перезагрузить",
      "plesk": "Панель Plesk",
      "console": "открыть консоль",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeHosting": "удалить лицензию",
      "removeOrder": "удалить заказ"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Да, уверен",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      },
      "removeFromBasket": {
        "success": "Заказ успешно удалён"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.plesk-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.plesk-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
